.table-input-wrapper--error {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	position: relative;
	top: 12px;
  .error-text {
    font-size: 12px;
    margin-left: 11px;
    font-weight: 500;
  }
  input {
    border: 1px solid var(--ui-error-color);
  }
}
