.popup-submit .button--red {
	color: white;
	border: 0;
	background-color: #f44336;
}
.popup-submit .button--red:hover {
	background-color: red;
}
.popup-submit .button--black {
	color: rgb(0, 0, 0);
	border: 1px solid #777e91;
}
.popup-submit--column {
	flex-direction: column;
}

.popup textarea {
	width: 100%;
	height: 140px;
	border-radius: 5px;
}
.custom-dot {
	// font-size: 18px;
	padding: 0px 4px;
}
.popup-submit .button.button-block {
	background-color: #e2e2e2;
	cursor: default;
	color: white;
	border: 0;
}

.table--withdrawals .tr {
	grid-template-columns: 0.5fr 0.75fr 0.75fr 0.75fr 1fr 1fr 2fr 2.25fr 0.6fr;
}

.user-settings--settings {
	&--withdrawals {
		position: relative;
		// max-width: 200px;
		top: 70px;
	}
}

.settings-tabs .user-settings__item {
	//left: 10px;
	box-shadow: none;
}
.settings-tabs .user-settings--settings--withdrawals .user-settings__item {
	z-index: 20;
}

.table--withdrawals--fiat .tr {
	grid-template-columns: 0.8fr 1fr 0.8fr 0.75fr 2fr 1.5fr 2fr 2.25fr 2.25fr 0.6fr;
}

.count-label {
	min-height: 42px;
}
