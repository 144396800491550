.button-group {
	display: flex;

	.button-group__item {
		width: 94px;
		height: 38px;
		display: flex;
		align-items: center;
		justify-content: center;

		color: #777e90;
		background: white;

		&.active {
			color: white;
			background: #00da83;
		}

		&:first-child {
			border-radius: 8px 0 0 8px;
		}
		&:last-child {
			border-radius: 0 8px 8px 0;
		}
	}

	.button-group__vip {
		text-transform: uppercase;
	}
	.button-group__regular {
		text-transform: capitalize;
	}
}

// .table--fee.fiat .tr {
// 	grid-template-columns: 1.2fr 1fr 1fr 0.6fr;
// }
.table--fee.fiat .tr {
	grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 0.3fr;
}
.table.table--fee.crypto .tr {
	grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 0.3fr;
}

.VolumeDependsFeeSettings {
	.table-input {
		justify-content: normal;
	}
	.tr {
		grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr 1fr 0.3fr;
	}
}
.table--fee--margin-borrow .tr {
	grid-template-columns: 0.5fr 0.7fr 0.7fr 1fr;
}

.table--fee--p2p .tr {
	grid-template-columns: 0.5fr 0.7fr 0.7fr 0.7fr 0.7fr 1fr;
}
