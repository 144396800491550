

:root {
    /*--------Main--------*/  

    --font-color: #022A36;
    --font-color-secondary: #9EA2A9;
    --font-color-third: #778192;
    --font-color-fourth: #9996BE;
    --grey: #9EA2A9;
    
    --grey-color:#777E90; 
    --main-color: #00DA83; 
}
$blue-dark: #022A36;
$text-dark: #011E26;
$grey: #777E90;
$text-grey: #777E90;
$text-grey-light: rgba(119, 126, 144, 0.5);
$green: #00DA83;
$main-color: #00DA83;


$border-color: #e8e8e8;


