.title-block_checkbox {
	display: flex;
	&__box {
		display: flex;
		align-items: center;
		margin-left: 15px;
		@supports (-webkit-appearance: none) or (-moz-appearance: none) {
			input[type='checkbox'],
			input[type='radio'] {
				--active: #00da83;
				--active-inner: #fff;
				--border: #bbc1e1;
				--border-hover: #00da83;
				--background: #fff;
				--disabled: #f6f8ff;
				--disabled-inner: #e1e6f9;
				-webkit-appearance: none;
				-moz-appearance: none;
				height: 21px;
				display: inline-block;
				vertical-align: top;
				position: relative;
				margin: 0;
				cursor: pointer;
				border: 1px solid var(--bc, var(--border));
				background: var(--b, var(--background));
				transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
				&:after {
					content: '';
					display: block;
					left: 0;
					top: 0;
					position: absolute;
					transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
				}
				&:checked {
					--b: var(--active);
					--bc: var(--active);
					--d-o: 0.3s;
					--d-t: 0.6s;
					--d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
				}
				&:disabled {
					--b: var(--disabled);
					cursor: not-allowed;
					opacity: 0.9;
					&:checked {
						--b: var(--disabled-inner);
						--bc: var(--border);
					}
					& + label {
						cursor: not-allowed;
					}
				}
				&:hover {
					&:not(:checked) {
						&:not(:disabled) {
							--bc: var(--border-hover);
						}
					}
				}
				&:focus {
					box-shadow: 0 0 0 var(--focus);
				}
				&:not(.switch) {
					width: 21px;
					&:after {
						opacity: var(--o, 0);
					}
					&:checked {
						--o: 1;
					}
				}
				& + label {
					font-size: 14px;
					line-height: 21px;
					display: inline-block;
					vertical-align: top;
					cursor: pointer;
					margin-left: 4px;
				}
			}
			input[type='checkbox'] {
				&:not(.switch) {
					border-radius: 7px;
					&:after {
						width: 5px;
						height: 9px;
						border: 2px solid var(--active-inner);
						border-top: 0;
						border-left: 0;
						left: 7px;
						top: 4px;
						transform: rotate(var(--r, 20deg));
					}
					&:checked {
						--r: 43deg;
					}
				}
				&.switch {
					width: 38px;
					border-radius: 11px;
					&:after {
						left: 2px;
						top: 2px;
						border-radius: 50%;
						width: 15px;
						height: 15px;
						background: var(--ab, var(--border));
						transform: translateX(var(--x, 0));
					}
					&:checked {
						--ab: var(--active-inner);
						--x: 17px;
					}
					&:disabled {
						&:not(:checked) {
							&:after {
								opacity: 0.6;
							}
						}
					}
				}
			}
			input[type='radio'] {
				border-radius: 50%;
				&:after {
					width: 19px;
					height: 19px;
					border-radius: 50%;
					background: var(--active-inner);
					opacity: 0;
					transform: scale(var(--s, 0.7));
				}
				&:checked {
					--s: 0.5;
				}
			}
		}
	}
}
.table--market-maker .tr {
	-ms-grid-columns: 1fr 1fr 1.2fr 1fr 1fr 1fr 100px;
	grid-template-columns: 1fr 1fr 1.2fr 1fr 1fr 1fr 100px;
}
.table-buttons--block {
	margin-left: 10px;
}
.td {
	input {
		font-family: 'Roboto', sans-serif;
		font-size: 14px;
		display: inline-block;
		border: 1px solid #e6e8ec;
		border-radius: 4px;
		text-align: right;
		width: 85px;
		padding: 6px 12px;
		outline: none;
		color: var(--font-color);
	}
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		/* display: none; <- Crashes Chrome on hover */
		-webkit-appearance: none;
		margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
	}
}
// .icon-market-marker-bot-icon:before {
// 	content: '\e927';
// 	color: #777e91;
// }

.table--market-maker {
	.tr {
		grid-template-columns: 1fr 1fr 1fr 1fr 1.2fr 1fr 1fr 1fr 100px;
	}
}
