
@import "ui__var.scss";
@import "var.scss";


@mixin input-placeholder {
    &.placeholder { @content; }
    &:-moz-placeholder { @content; }
    &::-moz-placeholder { @content; }
    &:-ms-input-placeholder { @content; }
    &::-webkit-input-placeholder { @content; }
}


* {
    box-sizing: border-box;
}

button {
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
}

img {
    width: 100%;
}

svg {
    width: 100%;
    height: auto;
}

a {
    text-decoration: none;
}

.hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    clip: rect(0 0 0 0);
    overflow: hidden;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}

/*--------------Inputs---------------*/


.input {
    position: relative;
    width: 100%;
    margin-bottom: 15px;
    &__descr {
      display: block;
      color: var(--input-descr);
      font-weight: 500;
      font-size: 11px;
      line-height: 20px;
    }
    &__notification {
        margin: 8px 0 0 0;
        font-size: 14px;
        color: var(--ui-error-color);
    }
    &--error {
        .input-item {
            border: 2px solid var(--ui-error-color);
        }
    }
    &--success {
        .input-item {
            border: 2px solid var(--ui-success-color);
        }
    }
    &--numb{
        width: 40px;
        margin: 0 8px;
        .input-item{
            padding: 0;
            border: none;
            border-bottom: 2px solid var(--ui-main-color);
            border-radius: 0;
            font-size: 24px;
            text-align: center;
            &.active{
                border-color: var(--ui-input-border-color);
            }
        }
    }
    &__name {
        margin-bottom: 8px;
        font-weight: 500;
        font-size: 14px;
        line-height: 1.2;
        &--fw-500 {
            font-weight: 500;
        }
        &--fs-12 {
            font-size: 12px;
        }
        &--color2 {
            color: $text-grey;
        }
        &--fw-400 {
            font-weight: 400;
        }
    }
}

.input-error {
    margin-top: 4px;
    font-size: 14px;
    color: var(--ui-error-color);
}

.input-wrapper {
    position: relative;
}

.input-item {
    display: block;
    width: 100%;
    height: 50px;
    padding: 0 14px;
    border-radius: 5px;
    border: 1px solid var(--ui-input-border-color);
    outline: none;
    line-height: normal;
    transition: all 0.15s ease;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: $text-dark;
    @include input-placeholder {
        color: $text-grey-light;     
        font-weight: 400;        
        transition: all 0.15s ease;
    }
    &--mid-width {
      width: 308px;
    }
    &--left-icon {
        padding-left: 54px;
    }
    &--left-badge {
        padding-left: 76px;
    }
    &--left-badge-long {
        padding-left: 120px;
    }
    &--right-double-icon {
        padding-right: 60px;
    }
    &--pr-60 {
        padding-right: 60px;
    }
    &--right-icon-and-action {
        padding-right: 95px;
    }
    &--right-icon {
        padding-right: 45px;
    }
    &--bigger {
        height: 52px;
        font-size: 16px;
    }
    &--medium {
      height: 42px;
    }
    &--height-40 {
        height: 40px;
        
    }
    &:disabled {
      background: #FCFCFD;
    }
    &--fw-400 {
        font-weight: 400;
    }
    // &--underline {
    //   text-decoration: underline;
    // }
}

.input-icon {
    position: absolute;
    top: 50%;transform: translateY(-50%);
    display: flex;
    left: 15px;
    width: 24px;
    font-size: 20px;
    transition: all 0.3s ease;
    svg {
        width: 100%;
        height: auto;
    }
    .stroke path {
        stroke: #b6b6b6;
    }
    &--right {
        left: unset;
        right: 15px;
    }
    &--more-right {
        left: unset;
        right: 45px;
    }

    &--hidden-icon {
      visibility: hidden;
      opacity: 0;
    }
}

.input-timer {
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;

  svg {
    width: 100%;
    height: auto;
  }

  &__text {
    margin-right: 8px;
    color: #777E91;
    font-size: 16px;
    opacity: 0.5;
  }

  &__icon {
    width: 24px;
    font-size: 24px;
  }
}

.input-action {
    position: absolute;
    top: 50%;transform: translateY(-50%);
    display: flex;
    left: 15px;
    font-weight: 700;
    color: var(--ui-main-color);
    &--more-right  {
        left: unset;
        right: 45px
    }
    &--right {
        left: unset;
        right: 15px
    }
    
}

.password-type {
    display: none;
}

input[type="password"] ~.show-pass {
    .password-type {
        display: block;
    }
    .text-type {
        display: none;
    }
}

.show-pass {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;    
    display: flex;
    width: 16px;
    svg {
        width: 100%;
        height: auto;
    }
    .fill path {
        fill: #b6b6b6;
    }    
    .stroke path {
        stroke: #b6b6b6;
    }
}


.select-block {
    &__name {
        margin-bottom: 8px;
        font-size: 14px;
        font-weight: 500;

        &--pair {
          font-size: 15px;
        }
    }
    &--mb {
      margin-bottom: 16px;
    }
}

.select {
    position: relative;
    display: flex;
    align-content: center;
    height: 44px;    
    border-radius: 5px;
    border: 1px solid var(--ui-input-border-color);
    background: #fff;
    z-index: 3;
    &__current {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0 14px;
        color: inherit;
        &--unselect {
            color: #777E90 !important;
            font-weight: 400 !important;
        }
    }
    &__current-arrow {
        display: flex;
        flex: 0 0 11px;
        max-width: 11px;
        margin-left: 10px;
        .stroke path {
            stroke:  #424242;
        }
    }
    &__current-text {
        margin-right: auto;
    }
    &__img {       
        display: flex;
        width: 16px;
        align-items: center;
        margin-right: 12px;
    }
    &__drop {
        display: none;
        position: absolute;        
        left: 0;
        top: calc(100% + 10px);        
        width: 100%;       
        background: #FFFFFF;
        border: 1px solid var(--ui-input-border-color);
        box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.20);
        border-radius: 12px;
        z-index: 1;
        font-size: inherit;

        ul {
            >li {
                >a, >button {
                    display: flex;
                    align-items: center;
                    min-height: 20px;
                    width: 100%;
                    padding: 0 16px;                    
                    color: $grey;
                    font-size: 16px;
                    font-weight: 400;
                    transition: all 0.15s ease;
                    text-align: left;
                    &:hover {
                       color: var(--main-color);
                    }
                    
                }
                &.active {
                    >a, >button {
                        color: $text-dark;
                    }
                }
                &+li {
                    margin-top: 12px;
                }
            }
        }
    }
    &__drop-scroll {
        max-height: 212px !important;
        overflow: auto;
        //scrollbar-color:  #b6b6b6 transparent ;
        //scrollbar-width: thin;
        padding: 16px 0;
        &::-webkit-scrollbar {
          width: 4px; 
          height: 4px;          
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 2px;
          background: #b6b6b6;
        }
    }
    &.active {
        z-index: 5;
        .select__drop {
            display: block;
            padding-right: 4px;
        }
    }
    &--small {
        padding: 0;
        border: 0;
        width: 62px;
        z-index: auto;
        .select__drop {
            padding: 0;
            min-width: 100px;
        }
    }
    &--grey {
        background: #DADADA;
        padding: 0 15px;
        width: 74px;
    }
    &--light {
        .select__current {
            color: #b6b6b6;
            font-weight: 300;
            font-size: 16px;
            line-height: 18px;
        }
    }
    &--big {
        height: 52px;
        font-size: 16px;
    }

    &--medium-height {
      height: 40px;
      font-size: 15px;
    }
    &--text-color {
      color: #777E91;
    }

    &--regular {
        height: auto;
        border-radius: 4px;
        border: 1px solid #e6e8ec;
        .select__current {
            height: 46px;
            color: #777e91;
            color: rgba(119, 126, 145, 0.5);
            padding: 6px 14px;
        }
        .select__drop {
            margin-top: 10px;
            left: 0;            
        }
    }
    &--regular2 {
        width: 100%;
        height: auto;
        border-radius: 5px;
        .select__current {
            height: 48px;
            padding: 0 14px;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: $text-dark;
            justify-content: flex-start;
            gap: 4px;

            @media screen and (max-width: 575px) {
                height: 52px;
            }

            &--grey {
                opacity: 0.5;
                color: $grey;
            }

            &-currency {
                color: $grey;
            }
        }
        .select__icon {
            width: 34px;
            height: 34px;
        }
        .select__arrow {
            margin-left: auto;
        }
        .select__drop {
            border: 1px solid var(--ui-input-border-color);
        }
    }
    &--coin-list {
        .select__current {
            gap: 12px;
            font-weight: 600;            
            font-size: 16px;
        }
        .select__current-text {
            display: flex;
            gap: 12px
        }
        .select__current-currency {            
            font-weight: 400;
            color: var(--font-color-fourth);
        } 
        .select__drop ul {
            >li >a, >li > button {
                gap: 12px;                 
                color: var(--font-color);
                
            } 
        } 
    }
    &--coin-list-new {
        .select__current {
            gap: 12px;
            font-weight: 600;            
            font-size: 16px;
        }
        .select__current-text {
            display: flex;
            gap: 12px
        }
        .select__current-currency {            
            font-weight: 400;
            color: var(--font-color-fourth);
        } 
        .select__drop ul {
            >li >a, >li > button {
                gap: 12px;
                padding: 10px 16px;
                color: var(--font-color);
                border-bottom: 1px solid #eee;
            }
            >li {
                &+li {
                    margin-top: 0;
                }
            }
        }
        .select__drop-scroll {
            margin: 16px 0;
            padding: 0;
            max-height: 206px !important;
        }
    }
    &--sign-type {
        border: none;
        .select__current {
            gap: 4px;
            padding: 0;
        }
        .select__current-box {    
            display: flex;
            align-items: center;
            height: 30px; 
            padding: 0 14px;       
            border-radius: 4px;
            border: 1px solid #E6E8EC;
        }
        .select__current-arrow {
            margin-left: 0;
        }
        .select__drop ul {
            >li {
                >a, >button {
                    text-align: center;
                }
            }
        }
    }
    &--height-48 {       
        border-radius: 5px;
        height: 48px;
        .select__current {
            height: 46px;           
        }
    }
    &--height-42 {       
        border-radius: 5px;
        height: 42px;
        .select__current {
            height: 42px;           
        }

    }
    &--height-58 {
        height: 58px;
    }
    &--table-type {
        height: 32px;
        border-radius: 4px;
        .select__current {
            padding: 0 12px;
        }
        .select__drop {
            ul {
                li + li {
                    margin-top: 8px;
                }
            }
            ul>li>a, ul>li>button {
                font-size: 14px;
                &:hover, &:focus {
                    color: $text-dark;
                }
            }
        } 
        .select__drop-scroll {
            padding: 10px 0;
        }
    }
    &__drop-link {
        display: flex;
        align-items: center;
        gap: 9px;
        padding: 4px 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: $blue-dark;        
        cursor: pointer;
        transition: 0.2s;

        &:hover {
            color: $green;

            .select__drop-icon svg {
                path, circle {
                    stroke: $green;
                }
            }
        }
    }
    &__drop-icon {
        flex: 0 0 24px;
        min-width: 24px;
    }
}

/*--------------End_Inputs-----------*/




/*--------------Buttons-------------*/


button {
    background: none;
    border: none;
    outline: none;
    padding: 0;
}

.button {
    display: inline-flex;
    align-items: center;justify-content: center;
    width: auto;
    gap: 8px;
    min-width: 110px;
    height: 48px;
    padding: 0 15px;
    background: var(--ui-button-bg);
    border: none;    
    border-radius: 24px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #fff;
    cursor: pointer;    
    transition: all 0.3s ease;
    &:hover {
        background: var(--ui-button-bg-hover);
    }
    &--type2 {
        background: transparent;
        border: 1px solid var(--ui-button-bg);
        color: #000;
        &:hover {
            background: var(--ui-button-bg);
            color: #fff;
        }
    }
    &--second-type {
        background: transparent;
        border: 1px solid var(--ui-button-bg);  
        color: var(--ui-button-bg);
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        .stroke path {
            stroke: var(--ui-button-bg);
        }
        &:hover {
            background: var(--ui-button-bg);
            color: #fff;
            .stroke path {
                stroke: #fff;
            }
        }
    }
    &--type3 {
        background: var(--ui-main-color);
        &:hover {
            background: var(--ui-button-bg);
            color: #fff;            
        }
    }
    &--type4 {
        background: #fff;
        color: var(--font-color);
        .btn-icon svg path {
            fill:  var(--font-color);
        }
        &:hover {            
            color: #fff;          
            .btn-icon svg path {
                fill:  #fff;    
            }  
        }
        
    }
    &--type5 {
        background: #0295F3;
        &:hover {
            background: darken($color: #0295F3, $amount: 5);
        }
    }
    &--type6 {

    }
    &--del, &--cancel-type2, &--red-type {
        background-color: #F44336;
        &:hover {
            background-color: #F44336;
        }
    }
    &--green-type {
        background: var(--main-color);
    }
    &--cancel, &--outline{
        
        background: transparent;
        border: 1px solid #777E90;
        color: #011E26;
        &:hover {
            background: transparent;
            border-color: $text-dark;
            color: $text-dark;
        }
    }
    &--check {
        background-color: var(--ui-main-color);
    }
    &--green {
        background-color: #5BC044;
        &:hover {
            background: #27ae60;
        }
    }
    &--purple {
        background-color: #7840b0;
        &:hover {
            background-color: #5e2a92;
        }
    }
    &--grey {
        background-color: #777E91;
        &:hover {
            background: #5e6372;
        }
    }
    &--rounded {
        border-radius: 60px;
    }
    &--less-height {
        height: 36px;
    }
    &--height-30 {
        height: 30px;
        font-size: 14px;
        font-weight: 400;
    }
    &--size2{
        height: 38px;
        border-radius: 3px;
        font-size: 14px;
        font-weight: 400;
    }
    &--medium-height {
        height: 42px;
        border-radius: 21px;
        font-weight: 500;
    }
    &--bigger-height {
        height: 60px;
        font-size: 18px
    }
    &--full-width {
        width: 100%;
    }
    &--little {
      min-width: 80px;
    }
    &--small {
        width: 120px;
    }
    &--regular {
        width: 160px;
    }
    &--wide {
        width: 180px;
    }
    &--wider {
        width: 200px;
    }
    &--big {
        width: 240px;
    }
    &--auto-width-16 {
        width: auto;
        padding: 0 16px;
    }
    &--text {
        background: transparent;
        border: none;
        color: var(--ui-main-color);
        &:hover {
            background: transparent;
        }
    }
    &--br-12 {
        border-radius: 12px;
    }
    &--size-60 {
        height: 60px;
        font-size: 16px !important;
    }
   
    &:disabled {
        opacity: 1;
        background: $grey;
        border: none;
        color: #fff;
        cursor: default;
    }
    &__text {
        display: block;
        padding-left: 16px;
        &--padding-none {
            padding-right: 0;
            padding-left: 0;
        }
    }
    &__arrow {
        display: flex;
        align-items: center;
        margin: 0 9px;
        width: 6px;        
    }
    &__icon {
        display: flex;
        width: 12px;
        margin-left: 10px;
        .fill path{
            fill: #fff;
        }
    }
}


/*--------------End_Buttons---------*/




/*-----Checkbox and Radiobutton-----*/


.checkbox {
    margin: 0 0 10px 0;
    &__item {
        display: flex;
        align-items: center;justify-content: center;        
        flex: 0 0 16px;
        width: 16px;
        height: 16px;
        border: 1px solid var(--ui-checkbox-border);
        border-radius: 3px;
        cursor: pointer;
    }
    &__item-icon {
        display: flex;
        width: 10px;
        opacity: 0;
        .fill path {
            fill: #fff;
        }
    }
    &__label {
        display: flex;
        align-items: center;
        
    }
    &__text {
        margin-left: 10px;
        font-size: 14px;
        cursor: pointer;
        color: var(--ui-checkbox-color);
        a {
            color: blue;
            text-decoration: underline !important;
        }
        &--for-mobile {
            display: none;
            @media screen  and (max-width: 1199px){
                display: block;
            }
        }
    }
    input:checked~.checkbox__item {
        background: var(--ui-checkbox-bg);
        border-color: var(--ui-checkbox-bg);
        .checkbox__item-icon {
            opacity: 1;
        }
    }
    input:disabled ~.checkbox__item{
        cursor: default;
        background: #b6b6b6;
        &+.checkbox__text {
            cursor: default;
        }
    }
    &--no-mb, &--mb-0 {
        margin-bottom: 0;
    }
    &--type2 {
        input:checked~.checkbox__item {
            background: transparent;
            border-color: var(--ui-checkbox-border);
            .checkbox__item-icon {
                opacity: 1;
                svg path {
                    fill: var(--ui-checkbox-bg);
                }
            }
        }
    }
    &--like-radio {
        .checkbox__item {
            position: relative;
            flex: 0 0 21px;
            width: 21px;
            height: 21px;
            border-radius: 50%;
            &:after {
                content: '';
                position: absolute;
                top: 50%;left: 50%;
                transform: translate(-50%, -50%);
                width: 9px;
                height: 9px;
                border-radius: 50%;
                background: $green;
                z-index: 2;
                opacity: 0;
            }
        }
        input:checked~.checkbox__item {
            background: transparent;
            border: 1px solid var(--ui-checkbox-border);
            &:after {
                opacity: 1;                
            }
        }
    }

}

.radio {
    margin: 0 0 10px 0;
    &__item {
        position: relative;
        display: flex;
        align-items: center;justify-content: center;       
        flex: 0 0 16px;
        width: 16px;
        height: 16px;
        border: 1px solid var(--ui-checkbox-border);
        border-radius: 50%;
        cursor: pointer;
        &:after {
            content: '';
            position: absolute;
            top: 50%;left: 50%;
            transform: translate(-50%, -50%);
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background: var(--ui-checkbox-bg); 
            z-index: 2;
            opacity: 0;
        }
    }

    &__label {
        display: flex;
        align-items: center;     
        gap: 8px;   
    }
    &__text {        
        font-size: 14px;
        cursor: pointer;
        a {
            color: blue;
        }
    }
    input:checked~.radio__item {
        
        &:after {
            opacity: 1;
        }
    }
    input:disabled ~.radio__item{
        cursor: default;
        background: #b6b6b6;
        &+.radio__text {
            cursor: default;
        }
    }
    &--size2 {
        .radio__item {
            flex: 0 0 20px;
            width: 20px;
            height: 20px;
            &::after {
                width: 10px;
                height: 10px;
            }
        }
    }
    &--type2 {
        .radio__item {
          flex: 0 0 24px;
          width: 24px;
          height: 24px;
          border-color: #BBBFC7;
          &::after {
            width: 12px;
            height: 12px;
          }
        }
        .radio__text {
          display: flex;
          align-items: center;
          gap: 10px;
          font-weight: 400;          
          line-height: 150%;
          color: #011E26;
          &--small {
            font-weight: 400;
            font-size: 14px;
            color: #777E91;
          }
        }
        .radio__icon {
          display: flex;
          width: 28px;
        }
        input:checked ~ .radio__item {
            background: inherit;
            border-color: #00DA83;
            &::after {
                background: #00DA83;
                opacity: 1;
            }
        }
          
    }
    &--no-mb {
        margin-bottom: 0;
    }

}

.switch {
    display: flex;
    align-items: center;
    &__toggler {
        position: relative;
        display: block;
        width: 36px;        
        height: 21px;        
        // border: 1px solid var(--ui-swith-color);
        border-radius: 18px;
        cursor: pointer;
        &:before {
            content: "";
            position: absolute;
            left: 2px;
            top: 50%;transform: translateY(-50%);
            width: 15px;
            height: 15px;
            background: var(--ui-swith-color);
            border-radius: 100%;
            transition: .3s;
        }                
    }
    input:checked~.switch__toggler {
        //background: #e8e8e8;
        &:before {
            left: 19px;
            background: var(--ui-main-color);
        }
    }
    &__label {
        &+.switch__text {
            margin-left: 10px;
        } 
    }
    &__text {
        font-size: 14px;
        font-weight: 500;
        &+.switch__label {
            margin-left: 10px;
        }
    }
    &--type2 {
        .switch__toggler {
            background: #777E91;
            &:before {
                background: #fff;
            }
        }
        input:checked~.switch__toggler {
            background: var(--ui-main-color);
            &:before {
                background: #fff;
            }
        }
    }
    &--auto-height {
        height: auto !important;
    }
}


/*---End_Checkbox and Radiobutton---*/



/*-------------Popup----------------*/


.popup-open {
    position: relative;
    overflow: hidden;
}

.popup-window {
    position: fixed;
    display: block ;    
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    overflow: auto;
    z-index: 201;
    background: rgba(0,0,0,0.5);
    &__inside {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 100%;
        padding: 20px ;
    }
}

.popup {
    position: relative;
    width: 460px;
    max-width: 100%;
    border-radius: 10px;
    background: var(--ui-popup-bg);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    padding: 30px 32px;
    &__close {
        position: absolute;
        top: 15px;
        right: 16px;
        width: 16px;
        svg {
            width: 100%;
            height: auto;
        }
        svg path {
            stroke: $blue-dark;
        }
        .fill path {
            fill: #000;
            stroke: transparent;
        }
        
    }
    &--medium {
      width: 400px;
    }
    &--small {
      width: 340px;
      padding: 16px;
    }
    &--small-medium {
      width: 366px;
      padding: 16px;
    }
    &--bigger {
        width: 483px;
    }
    &--width-890 {
        width: 890px;
    }
}

.popup-header {
    &__title {
        text-align: center;
        font-size: 18px;
        line-height: 1.6;
        font-weight: 700;
        color: var(--font-color);
        &--bigger {
            font-size: 20px;
        }
    }
    &__subtitle {
        text-align: center;
        font-size: 18px;
        color: $text-grey;
    }
}

.popup-body {
    margin-top: 12px;
    &--type2 {
        margin-top: 24px;
    }
    &--type3 {
        margin-top: 40px;
    }
    &--mt-0 {
        margin-top: 0;
    }
}

.popup-text {
    margin: 0 0 15px 0;
    font-size: 14px;
    line-height: 1.35;
    color: #777E91;
    &--center {
        text-align: center;
    }
    &--bigger {
        font-size: 16px;
    }
}

.popup-submit {
    display: flex;
    justify-content: center;
    margin-top: 24px;
    &--less-mt {
        margin-top: 15px;
    }
    &--more-mt {
        margin-top: 40px;
    }
    &--mt-32 {
        margin-top: 32px;
    }
    &__col {
        width: calc(50% - 10px);
    }
    &--sb {
        justify-content: space-between;
    }
    
}




@media screen and (max-width: 767px) {
    // .popup {
    //     padding: 30px 15px 20px 15px;
    // }
    .popup-submit {
      .button:not(:last-child) {
        margin-right: 5px;
      }
    }

    .popup-header__title {
        font-size: 18px;
    }
}
/*-------------End_Popup------------*/


/*-------------Form, Authorization----------------*/


.authorization-section {
    display: flex;
    align-items: center;
    min-height: 100vh;
    padding: 30px 0;
    &__container {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
    }
}

.authorization {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    &__title {
        text-align: center;
        font-weight: 700;
        font-size: 24px;
    }
    &__field {
        &+.authorization__field {
            margin-top: 20px;
        }
        &--numb{
            display: flex;
            justify-content: center;
            margin-bottom: 40px;
        }
    }
    &__details {
        margin-top: 16px;
        font-size: 14px;
        text-align: center;
        a, button {
            color: var(--ui-main-color);
            font-weight: 700;
        }
    }
    &__form {
        margin-top: 34px;
    }
    &__options {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 10px;
    }
    &__options-full {
        width: 100%;
    }
    &__forgot-link {
        color: var(--ui-color-grey);
        font-size: 14px;
    }
    &__photo{
        margin: 25px 0;
        img{
            max-width: 100%;
        }
    }
}

.form-submit {
    margin-top: 34px;
}
/*-------------END_Form, Authorization----------------*/




/*-------------Notification----------------*/

.notification-wrapper{
    position: absolute;
    right: 25px;
    top: 25px;
    z-index: -100;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
    &.active{
        visibility: visible;
        opacity: 1;
        z-index: 10000;
    }
}

.notification{
    position: relative;
    width: 410px;
    max-width: 100%;
    padding: 15px 20px;
    text-align: left;
    box-sizing: border-box;
    border-radius: 18px;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 2px 25px rgba(0, 0, 0, 0.15);
    background: #FFFFFF;
    transform: translateX(150%);
    transition: 0.5s;
    &.active{
        transform: translateX(0);
    }
    & + &{
        margin-top: 15px;
    }
    &--error, &--successful{
        .notification__title{
            color: #fff;
        }
        .notification__text{
            color: #fff;
        }
        .notification__close{
            svg{
                path{
                    fill: #fff;
                }
            }
        }
    }
    &--error{
        background: #EB5757;
    }
    &--successful{
        background: #4BC0B1;
    }
    &--icon-error{
        display: flex;
        align-items: stretch;
        padding: 0;
        .notification__icon{
            background: #EB5757;
        }
        .notification__info{
            padding: 15px;
        }
    }
    &--icon-successful{
        display: flex;
        align-items: stretch;
        padding: 0;
        .notification__icon{
            background: #4BC0B1;
        }
        .notification__info{
            padding: 15px;
        }
    }
    &__close{
        position: absolute;
        top: 10px;
        right: 10px;
    }
    &__title{
        font-weight: 600;
        font-size: 16px;
        color: #4C4C4C;
    }
    &__text{
        margin-top: 5px;
        font-size: 14px;
        line-height: 150%;
        color: #4C4C4C;
    }
    &__icon{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        width: 62px;
        min-width: 62px;
        border-radius: 18px 0 0 18px;
        svg{
            width: 30px;
            margin: 0 auto;
        }
    }
}



/*-------------END_Notification----------------*/


/*------------Pagination---------------------*/


.pagination-block {
    display: flex;
    justify-content: center;
    margin-top: 60px;
    padding: 0 5px;
    &--end {
        justify-content: flex-end;
    }
    &--start {
        justify-content: flex-start !important;
    }
}

.pagination {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
    >li {
        padding: 0;
        >a,>button {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            // tem-disable
            // min-width: 40px;
            margin-top: 10px;
            padding: 0 2px;
            border: 1px solid var(--ui-pagination-border);
            border-radius: 3px;
            background: var(--ui-pagination-bg);
            color: var(--ui-pagination-color);
            transition: all 0.15s ease-in;
        }
        &.arrow {
            svg {
                width: 10px;
                height: auto;
            }
            .stroke path {
                stroke:  var(--ui-pagination-color);
                transition: all 0.15s ease-in;
            }
            &.disabled {
                >a,>button {
                    pointer-events: none;
                }
                svg {
                    opacity: 0.3;
                }
            }
            
        }
        &:not(.disabled){
            &:hover, &.active {
                >a,>button {
                    background: var(--ui-main-color);
                    color: #fff;
                    border-color: var(--ui-main-color);
                }
                .stroke path {
                    stroke: #fff;
                }
            }
            
        }
    }
}
/*------------END_Pagination----------------*/


/*------------------Captcha----------------*/
.captcha-modal {
    position: relative;
    width: 360px;   
    background-color: var(--ui-popup-bg);
    box-shadow: 0px 0px 19.32px 1.68px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    color: var(--ui-text-color);
    padding: 15px;
}

.captcha-cover {
    position: relative;
    height: 220px;
    padding: 0 15px;
    &__item {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
    } 
}

.captcha-text {
    position: absolute;
    top: 15px;
    left: 0;
    padding: 0 15px;
    z-index: 2;
    color: #fff;
    font-weight: 700;
    font-size: 20px;
    line-height: 1.4;
    span {
        color: var(--ui-main-color);
    }
}

.captcha-move {
    position: absolute;
    bottom: 20px;
    left: 0%;
    margin-left: 10px;
    width: 60px;
    z-index: 2;
}

.capthca-default {
    position: absolute;
    bottom: 20px;
    left: 60%;
    width: 60px;
    margin-left: 10px;
}

.capthca-slide-block {
    padding: 20px;
}

.capthca-slide {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    padding-left: 40px;
    border-radius: 25px;
    background: #EEEFEE;
    border: 1px solid var(--ui-border-color);
    text-align: center;
    font-size: 15px;
    color: rgba(150,150,150,0.5);
}

.slide-btn {
    position: absolute;
    margin-left: -10px;
    top: 50%; transform: translateY(-50%);
    left: 0;
    display: flex;
    align-items: center;justify-content: center;
    width: 64px;
    height: 64px;    
    border-radius: 50%;
    background: var(--ui-main-color);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 2;      
    svg {
        width: 24px;
        height: auto;
        pointer-events: none;

    }
    .stroke path {
        stroke: #fff;
    }
    
    &.success{
        .stroke path {
            stroke: #fff;
        }
    }
    &.error {
        .stroke path {
            stroke: #ed1c24;
        }        
    }

}

.capthca-actions {
    display: flex;
    padding:  15px 15px 0 15px;
    width: calc(100% + 30px);
    margin-left: -15px;
    border-top: 1px solid var(--ui-border-color);
    &__item {
        display: flex;
        align-items: center;
        &+.capthca-actions__item {
            margin-left: 15px;
        }
        svg {
            width: 20px;
            height: auto;
        }
        svg path {
            stroke: #121214;
        }
    }
}
/*------------------End_Captcha------------*/


/*------------------Avatars------------*/

.user-avatar {
    display: flex;
    align-items: center;
    &--xl-size {
        .user-avatar__info-name {
            font-size: 16px;
        }
        .user-avatar__info-text{
            font-size: 12px;
        }
    }
    &--m-size {
        .user-avatar__info-name {
            font-size: 14px;
        }
        .user-avatar__info-text{
            font-size: 11px;
        }
    }
    &--s-size{
        .user-avatar__info-name {
            font-size: 12px;
        }
        .user-avatar__info-text{
            font-size: 9px;
        }
    }
    // visible
    &__info {
        padding-left: 10px;
    }
    &__info-name {
        color: var(--ui-text-color);
        font-weight: 700;
        line-height: 1.4;
    }
    &__info-text {
        color: var(--ui-text-color-light);
    }
}

.avatar {
    position: relative;
    display: flex;
    align-items: center;justify-content: center;
    border-radius: 50%;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
    }
    &--xl-size {
        width: 48px;
        height: 48px;
    }
    &--m-size {
        width: 40px;
        height: 40px;
        .avatar__text {
            font-size: 16px;
        }
    }
    &--s-size{
        width: 32px;
        height: 32px;
        .avatar__text {
            font-size: 14px;
        }
    }
    &--xs-size {
        width: 24px;
        height: 24px;
        .avatar__text {
            font-size: 12px;
        }
    }
    &--xxs-size {
        width: 16px;
        height: 16px;
        .avatar__text {
            font-size: 8px;
        }
    }
    &__text {
        color: #fff;
        font-weight: 700;
        text-align: center;
    }
    &--no-photo {
        background: var(--ui-main-color);
    }
    &__text {
        font-size: 20px;
    }
}

.avatars-group {
    display: flex;
    .avatar {
        border: 2px solid #fff;
        z-index: 10;
        &:nth-child(2) {
            z-index: 9;
        }
        &:nth-child(3) {
            z-index: 8
        }
        &:nth-child(3) {
            z-index: 7
        }
        &:nth-child(4) {
            z-index: 6
        }
        &:nth-child(5) {
            z-index: 5;
        }
    }
    &--xl-size {
        .avatar + .avatar {
            margin-left: -20px;
        }  
    }
    &--m-size {
        .avatar + .avatar {
            margin-left: -15px;
        }  
    }
    &--s-size {
        .avatar + .avatar {
            margin-left: -10px;
        }  
    }
}

/*------------------End_Avatars------------*/


/*----------------Blog_and_article------------*/

.blog-section {
    padding: 45px 0;
}

.blog-container {  
    margin: 0 auto;
}

.blog {    
    padding-top: 20px;
    &__row {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -30px;
    }
    &__col {
        width: 33.3%;
        padding: 0 30px;
    }
}

.blog-card {
    margin-top: 30px;
    &__img {
        height: 201px;
        img {
            width: 100%;height: 100%;
            border-radius: 18px;
            object-fit: cover;
        }
    }
    &__title {
        display: block;
        margin-top: 20px;
        font-size: 19px;
        color: var(--ui-text-color);
        font-weight: 700;
        transition: all 0.15s ease-in;
        &:hover {
            color: var(--ui-main-color);
        }
    }
}

@media screen and (max-width: 991px) {
    .blog {
        &__row {
            margin: 0 -15px;
        }
        &__col {
            padding: 0 15px;
        }
    }

    .blog-card {
        margin-top: 20px;
        &__title {
            font-size: 17px;
        }
    }
}

@media screen and (max-width:767px) {
    .blog-section {
        padding: 30px 0;
    }

    .blog {      
        padding-top: 10px;  
        &__col {
            width: 50%;
        }
    }
}

@media screen and (max-width:475px) {    

    .blog {              
        &__col {
            width: 100%;
        }
    }
}

/*----------------End_Blog_and_article------------*/


/*----------------Text and title------------*/

.section-title {    
    font-size: 42px;
    font-weight: 700;    
    line-height: 1.4;
    &--centered {        
        text-align: center;
    }
    &--right {       
        text-align: right;
    }
}

.block-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 1.4;
    &--centered {        
        text-align: center; 
    }
    &--right {        
        text-align: right;
    }
}

.h1, .h2,.h3,.h4,.h5 {
    font-weight: 700;
    line-height: 1.4;
}

.h1 {
    font-size: 42px;
}
.h2 {
    font-size: 36px;
}
.h3 {
    font-size: 30px;
}
.h4 {
    font-size: 24px;
}
.h5 {
    font-size: 18px;
}

.text-block {
    margin-top: 30px;
    line-height: 2;
    font-size: 15px;
    &--mt15 {
        margin-top: 15px;
    }
    &--no-mt {
        margin-top: 0;
    }
    p {
        &+p {
            margin-top: 15px;
        }
        a {
            color: var(--ui-main-color);
            text-decoration: underline !important;
            font-weight: 700;
        }       
    }
    
    &--centered {
        text-align: center;
    }
    &--right {
        text-align: right;
    }
}

.bold {
    font-weight: 700;
}

/*----------------End_Text and title------------*/


/*----------------Loader-----------------------*/

.loader-modal {
    position: fixed;
    display: flex;    
    align-items: center;justify-content: center;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0,0,0,0.65);
    z-index: 210;
}

.loader-circle {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {    
      width: 100px;
      height: 100px;
      overflow: visible;
      transform: rotate(-90deg);
    }
    &__item {      
        width: 100px;
        height: 100px;
        stroke-dasharray: 314;      
      &--type1 {       
        fill: none;
        stroke-width: 6px; 
        stroke: rgba(255, 255, 255, 0.2);
        
      }
      &--type2 {              
        fill: none;
        stroke-width: 8px;
        stroke: var(--ui-main-color);       
        stroke-linecap: round;               
        transform-origin: center center;
        animation:  loader-stroke 3s infinite linear, loader-rotation 2s infinite cubic-bezier(.45,.85,.72,.71) ;         
      }
    }   
    &--md {
        svg {
            width: 70px;
            height: 70px;
        }
        .loader-circle__item {
            width: 70px;
            height: 70px;
            stroke-dasharray: 220px;  
            &--type1 {
                stroke-width: 5px; 
            } 
            &--type2 {
                stroke-width: 6px;
                animation:  loader-stroke-md 2.6s infinite linear, loader-rotation 1.8s infinite cubic-bezier(.45,.85,.72,.71) ;        
            }
        }
    }
    &--sm {
        svg {
            width: 40px;
            height: 40px;
        }
        .loader-circle__item {
            width: 40px;
            height: 40px;
            stroke-dasharray: 126px;  
            &--type1 {
                stroke-width: 3px; 
            } 
            &--type2 {
                stroke-width: 4px;
                animation:  loader-stroke-sm 2.4s infinite linear, loader-rotation 1.6s infinite cubic-bezier(.45,.85,.72,.71) ;        
            }
        }
    }
}

@keyframes loader-rotation {
    0% {            
      transform: rotate(0);
    }
    100%{
      transform: rotate(360deg);
    }
}

@keyframes loader-stroke {
    0% {      
      stroke-dashoffset: 314;      
    }
    100%{    
      stroke-dashoffset: -314;
      
    }
}

@keyframes loader-stroke-md {
    0% {      
      stroke-dashoffset: 220;      
    }
    100%{    
      stroke-dashoffset: -220;
      
    }
}

@keyframes loader-stroke-sm {
    0% {      
      stroke-dashoffset: 126;      
    }
    100%{    
      stroke-dashoffset: -126;
      
    }
}
/*----------------End_Loader-------------------*/

/*----------------File_upload-------------------*/

.upload-file-box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 220px;
    width: 370px;
    overflow: hidden;
    border: 1px dashed var(--ui-input-border-color);
    border-radius: 10px;
    transition: all 0.15s ease-in;
    cursor: pointer;
    &:hover {
        border-color: var(--ui-upload-box-color);
        .upload-file__label {
            color: var(--ui-upload-box-color);
        }
    }
    &:focus {
        border-style: solid;
    }
    &--error {
        border-color: var(--ui-error-color) !important;
        .upload-file__label {
            color: var(--ui-text-color) !important;
        }
    }
}

.error-text {
    margin-top: 4px;
    font-size: 14px;
    color:  var(--ui-error-color);
}

.file-input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    text-indent: -999px;
    z-index: 5;
    
}

.upload-file {
    width: 100%;
    padding: 20px 40px;
    text-align: center;
    &__icon {
        display: flex;
        align-items: center;
        width: 30px;
        margin: 0 auto;
        svg {
            width: 100%;
            height: auto;
        }
    }
    &__title {
        display: block;
        margin-top: 15px;
        font-size: 18px;
        font-weight: 500;
        color: var(--ui-text-color);
    }
    &__text {
        display: block;
        margin-top: 15px;
        font-size: 14px;
        color: var(--ui-text-color-light);
    }
    &__label {
        display: inline-block;
        margin-top: 16px;
        padding: 6px 20px;
        border: 1px solid var(--ui-input-border-color);
        border-radius: 4px;
        box-shadow: 0px 1px 2px rgba(6, 25, 56, 0.05);
        font-size: 14px;
        font-weight: 700;
        transition: all 0.15s ease-in;
    }
    .progress-bar {
        margin-top: 16px;
    }    
}

.upload-photo-wrap {
    position: relative;
}

.upload-photo-box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 170px;
    width: 260px;
    overflow: hidden;
    border: 1px dashed var(--ui-input-border-color);
    border-radius: 4px;
    transition: all 0.15 ease-in;
    cursor: pointer;
    .loader-circle__item--type1 {
        stroke: #e0e0e0;
    }
    &--preview {
        border-color: transparent;
    }
    &--error {
        background: #FFF4F4;
        border-color: transparent;
        .upload-photo__info {
            color: var(--ui-error-color);
        }
    }
}

.upload-photo {
    text-align: center;
    &__icon {
        display: flex;
        align-items: center;justify-content: center;
        margin: 0 auto;
        width: 55px;
        svg {
            width: 100%;
            height: auto;
        }
        &--error {
            height: 55px;
            border: 2px solid #EED5D5;
            border-radius: 50%;
            svg {
                width: 30px;
            }
        }
    }
    &__info {
        display: block;
        margin-top: 16px;
        font-size: 13px;
        color: #9EA2A9;
    }
    &__info-text {
        display: block;
        margin-top: 4px;
        font-weight: 500;
    }
    
}

.upload-photo-preview {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.upload-photo-hint {
    margin-top: 4px;
    font-size: 12px;
    color: var(--ui-text-color-grey);
}

.upload-photo-action {
    position: absolute;
    display: flex;
    align-items: center;    
    right: 10px;
    bottom: 10px;
    z-index: 6;
    &__btn {
        position: relative;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border-radius: 3px;
        cursor: pointer;
        &+.upload-photo-action__btn {
            margin-left: 10px;
        }
        &--edit {
            background: var(--ui-main-color);
            svg {
                width: 15px;
            }
            .fill path {
                fill: #fff;
            }
        }
        &--remove {
            background: #fff;
            svg {
                width: 11px;
            }
            .stroke path {
                stroke: var(--ui-text-color);
            }
        }
        svg {
           
            height: auto;
        }
    }
}

.file-attachment {
    display: inline-flex;
    margin: 15px 0;
    label {
        cursor: pointer;
    }
    &__title {
        display: flex;
        align-items: center;
        font-weight: 500;
        color: var(--ui-main-color);
        svg {
            width: 15px;
            margin-left: 5px;
        }
        .stroke path {
            stroke: var(--ui-main-color);
        }
    }
    &__text {
        margin-top: 6px;
        font-size: 14px;
        color: var(--ui-text-color-light);
    }
}

/*----------------End_File_upload---------------*/


/*----------------Progress-bar-----------------*/
.progress-bar {
    position: relative;
    display: block;
    &__line {
        display: block;
        position: relative;
        height: 5px;
        background: var(--ui-progress-bg);
        border-radius: 2px;
    }
    &__line-active {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        height: 100%;
        background: var(--ui-progress-line);
        border-radius: 2px;
        transition: all 0.15s linear;
    }
    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
    }
}

.progress-bar-label {
    position: absolute;
    bottom: calc(100% + 10px);
    display: flex;
    justify-content: center;align-items: center;
    padding: 0 5px;
    margin-left: -30px;
    height: 30px;
    width: 60px;    
    border-radius: 8px;
    text-align: center;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    background: var(--ui-progress-label-bg);
    &:after {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 100%;
        border: 7px solid transparent; border-top: 6px solid var(--ui-progress-label-bg);
    }
}

/*----------------End_Progress-bar-------------*/

/*----------------FAQ--------------------------*/


.faq-box{
    margin-top: 50px;
    padding: 50px 15px;
    background: #F2F2F2;
    border-radius: 18px;
}

.faq-list {
    max-width: 650px;
    margin: 0 auto;
}

.faq {
    padding: 15px;
    background: #fff;   
    border: 1px solid var(--ui-border-light-color);    
    border-radius: 10px;
    &__header {
        display: flex;
        align-items: center;
        
    }
    &__header-title {
        font-weight: 600;
        flex: 1 1 0;
    }
    &__header-btn {
        display: flex;
        flex: 0 0 16px;
        max-width: 16px;
    }
    &__text {
        display: none;  
        border-top: 1px solid transparent;    
        font-size: 14px;     
    }
    &.active {
        .faq__header-btn {
            transform: rotate(-180deg);
        }
        .faq__text {       
            display: block;     
            margin-top: 10px;
            padding-top: 10px;        
            border-color:  var(--ui-border-light-color);     
        }
    }
    &+.faq {
        margin-top: 15px;
    }
}

@media screen and (max-width:767px) {    

    .faq-box {
        margin-top: 30px;
        padding: 30px 15px;
    }
}

@media screen and (max-width:445px) {

  .input-item {
    &--mid-width {
      width: 100%;
    }
  }
}
/*----------------END_FAQ----------------------*/



