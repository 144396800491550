@font-face {
	font-family: 'icomoon';
	src: url('../fonts/font/icomoon.eot?46my62');
	src: url('../fonts/font/icomoon.eot?46my62#iefix') format('embedded-opentype'),
		url('../fonts/font/icomoon.ttf?46my62') format('truetype'),
		url('../fonts/font/icomoon.woff?46my62') format('woff'),
		url('../fonts/font/icomoon.svg?46my62#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-info-icon:before {
	content: '\e900';
	color: #777e91;
}

.icon-plus-icon:before {
	content: '\e901';
	color: #00da83;
}

.icon-eye-password-icon:before {
	content: '\e902';
	color: #777e91;
}

.icon-lock-icon:before {
	content: '\e903';
	color: #777e91;
}

.icon-mail-icon:before {
	content: '\e904';
	color: #777e91;
}

.icon-password-icon:before {
	content: '\e905';
	color: #777e91;
}

.icon-timer-icon:before {
	content: '\e906';
	color: #00da83;
}

.icon-FA-document-icon:before {
	content: '\e907';
	color: #777e91;
}

.icon-cancel-icon:before {
	content: '\e908';
	color: #f44336;
}

.icon-close-lock-icon:before {
	content: '\e909';
	color: #777e91;
}

.icon-eye-icon:before {
	content: '\e90a';
	color: #777e91;
}

.icon-lamp-icon:before {
	content: '\e90b';
	color: #00da83;
}

.icon-open-lock-icon:before {
	content: '\e90c';
	color: #777e91;
}

.icon-pagination-left-icon:before {
	content: '\e90d';
	color: #777e91;
}

.icon-pagination-right:before {
	content: '\e90e';
	color: #00da83;
}

.icon-search-icon:before {
	content: '\e90f';
	color: #777e91;
}

.icon-status-arrow-icon:before {
	content: '\e910';
}

.icon-succes-icon:before {
	content: '\e911';
	color: #00da83;
}

.icon-test:before {
	content: '\e912';
	color: #777e91;
}

.icon-trash-icon:before {
	content: '\e913';
	color: #f44336;
}

.icon-unlock-icon:before {
	content: '\e914';
}

.icon-wallet-icon:before {
	content: '\e915';
	color: #00da83;
}

.icon-admin-managment-icon:before {
	content: '\e916';
	color: #777e91;
}

.icon-admin-panel-icon:before {
	content: '\e917';
	color: #777e91;
}

.icon-allocation-roles-icon:before {
	content: '\e918';
	color: #777e91;
}

.icon-fee-management-icon:before {
	content: '\e919';
	color: #777e91;
}

.icon-finacial-system-icon:before {
	content: '\e91a';
	color: #777e91;
}

.icon-ipWhitelisting-icon:before {
	content: '\e91b';
	color: #777e91;
}

.icon-limitations-icon:before {
	content: '\e91c';
	color: #777e91;
}

.icon-notification-icon:before {
	content: '\e91d';
	color: #777e91;
}

.icon-resize-sidebar-icon:before {
	content: '\e91e';
	color: #777e91;
}

.icon-settings-icon:before {
	content: '\e91f';
	color: #777e91;
}

.icon-trading-pairs-icon:before {
	content: '\e920';
	color: #777e91;
}

.icon-transactions-icon:before {
	content: '\e921';
	color: #777e91;
}

.icon-user-managment-icon:before {
	content: '\e922';
	color: #777e91;
}

.icon-withdrawals-icon:before {
	content: '\e923';
	color: #777e91;
}

.icon-logout-icon:before {
	content: '\e924';
	color: #fff;
}

.icon-arrow-down-icon:before {
	content: '\e925';
	font-size: 10px;
}

.icon-edit-icon:before {
	content: '\e926';
	color: #00da83;
}
