.currencies-general {
	border: 1px solid #e6e8ec;
	border-radius: 10px;
}
.currencies-general .tr {
	grid-template-columns: 0.5fr 0.8fr 0.9fr 1fr;
}
.currencies-p2p {
	border: 1px solid #e6e8ec;
	border-radius: 10px;
}
.currencies-p2p .tr {
	grid-template-columns: 1fr 1fr;
}
.currencies {
	.td:last-child {
		margin-right: 50px;
	}
	.switch {
		display: flex;
		justify-content: right;
	}
	@media screen and (max-width: 1200px) {
		.switch {
			display: flex;
			justify-content: left;
		}
	}
	@media screen and (max-width: 400px) {
		.switch {
			display: flex;
			justify-content: left;
		}
	}
	.list-loader {
		background: #fff;
		border: 1px solid #e6e8ec;
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 400px;
	}
}
