.btn-2fa {
	&.btn-2fa--disable {
		background: #f44336;
		&:hover {
			background: #d6281b;
		}
	}

	.lds-dual-ring {
		&:after {
			border: 6px solid #fff;
			border-color: #fff transparent #fff transparent;
		}
	}

	.lds-dual-ring-small {
		&:after {
			border: 3px solid #fff;
			border-color: #fff transparent #fff transparent;
			animation: lds-dual-ring-small 1.2s linear infinite;
		}
	}

	.btn-2fa-loading-text {
		margin-left: 8px;
	}
}

.settings-tabs {
	.user-settings {
		&__item {
			cursor: pointer;
		}
	}
	.react-tabs {
		&__tab-panel {
			.forms-block {
				padding-top: 0;
			}
		}
		&__tab {
			&--selected {
				background-color: #fff;
				box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
				cursor: default;
			}
		}
	}
	.form {
		&--settings {
			margin-top: 0;
		}
	}
}

.steps {
	&__code {
		.input-item {
			height: 48px;
		}
	}
	&__code-btn {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
	&__code-btn-submit {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		width: auto;
		padding: 10px;
		background: #00da83;
		border: none;
		border-radius: 5px;
		font-weight: 600;
		font-size: 14px;
		line-height: 1.3;
		color: #ffffff;
		cursor: pointer;
		transition: 0.3s;
		&:hover {
			background: #009a5b;
		}

		&:disabled {
			opacity: 0.5;
			background: #777e90;
			cursor: default;
		}
	}

	&__code-btn-cancel {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		width: auto;
		padding: 10px;
		background: #f44336;
		border: none;
		border-radius: 5px;
		font-weight: 600;
		font-size: 14px;
		line-height: 1.3;
		color: #ffffff;
		cursor: pointer;
		transition: 0.3s;
		margin-left: 10px;
		&:hover {
			background: #d6281b;
		}

		&:disabled {
			opacity: 0.5;
			background: #777e90;
			cursor: default;
		}
	}
}

.tfa-block {
	.input-item {
		padding-left: 15px;
	}
	.input {
		&__descr {
			display: none;
		}
	}
}

.check-2fa-enable-wrapper {
	width: 100%;
	max-width: 612px;
	min-height: 255px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.input--error {
	.icon-eye-password-icon {
		&::before {
			color: var(--ui-error-color);
		}
	}

	.icon-eye-icon {
		&::before {
			color: var(--ui-error-color);
		}
	}
	.input-icon--more-right {
		right: 40px;
	}
}

.form--settings .input--error .input-icon--error {
	display: block;
}

.form--settings .input--success .input-icon--success {
	display: block;
}

.form--settings {
	.input-item {
		padding-right: 65px;
	}
}

.input-icon--error {
	display: none;
	width: 24px;
	height: 30px;
	&::before {
		content: '\e908';
		font-size: 16px;
		color: #f44336;
		right: 55px;
	}
}

.icon-checkbox {
	width: 18px;
	height: 18px;
	display: none;
	&:before {
		content: '';
		right: 55px;
		width: 16px;
		height: 16px;
		display: block;
		background: url('../../img/icons/check_green.svg') no-repeat center center;
	}
}
.table--convert-settings .tr {
	grid-template-columns: 1fr 1fr 1fr;
}
.table--convert-settings .table-input {
	justify-content: flex-start;
}

.title-block.title-block--between {
	justify-content: space-between;
}
