.table--transaction-fiat .tr {
	grid-template-columns: 0.5fr 0.7fr 1.3fr 0.6fr 1.1fr 1fr 0.8fr 0.6fr 1fr;
}
.transactions-data-picker svg {
	height: 20px;
	width: 12px;
}
.input.error {
	.input-with-select {
		border: 1px solid #f44336;
	}
}
.error-message {
	color: #f44336;
}
.count-label {
	z-index: 15;
}
.count-label.count-label--zindex {
	z-index: 0;
}
.count-label-pending-withdrawal {
	margin-left: auto;
	width: fit-content;
}

.table--transaction-withdrawal-fiat .tr {
	grid-template-columns: 0.5fr 0.7fr 1.3fr 0.6fr 0.8fr 0.7fr 1.2fr 1fr 0.8fr 0.6fr 1fr;
}
.table--transaction-referral {
	& .tr {
		grid-template-columns: 0.8fr 1fr 1fr 2fr 0.8fr 0.8fr 1fr 1fr 1fr 1fr 1.3fr 1fr 1.2fr 1.2fr 1.2fr;
	}
}
.table--transaction-referral .table-header,
.table--transaction-referral .table-body {
	min-width: 1500px;
}

.table--transaction-convert {
	& .tr {
		grid-template-columns: 0.8fr 0.8fr 1.3fr minmax(100px, 0.8fr) 0.6fr 0.6fr 1fr 1fr 1fr 1fr;
	}
}
