.table--notification .tr {
	-ms-grid-columns: 1fr 1fr 1fr;
	grid-template-columns: 1fr 1fr 1fr;
	.switch {
		width: 37px;
		justify-content: center;
	}
	.td--center {
		display: flex;
		text-align: center;
		justify-content: center;
	}
	.td:last-child {
		margin-right: 60px;
	}
	@media screen and (max-width: 1200px) {
		.td--center {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}
	@media screen and (max-width: 400px) {
		.td--center {
			display: flex;
			flex-direction: column;
			align-items: start;
		}
	}
}
