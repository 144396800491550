// ==========================================:
.pie-chart-tooltip {
	border-radius: 0.25rem;
	background: #fafafa;
	color: #686666;
	padding: 1rem;
	text-align: center;
}

.chart {
	width: 100%;

	&__wrapper {
		width: 100%;
		max-width: 1014px;
	}
}
