.dispute {
	a {
		padding: 0;
	}
	.table-block {
		margin-top: 62px;
		padding-bottom: 52px;
	}
	.table {
		border-bottom: 1px solid #e8e8e8;
		border-radius: 0;
	}
	.td {
		&:first-child {
			p {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
		&:last-child {
			a {
				padding: 0;
				color: white;
				text-decoration: none;
			}
		}
	}
	.tr {
		grid-template-columns:
			1.5fr minmax(65px, 0.4fr) minmax(65px, 0.4fr) minmax(65px, 0.5fr) minmax(65px, 0.5fr)
			minmax(65px, 0.5fr) minmax(170px, 1fr) 120px 100px;
		div {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
	.pagination-block {
		margin-top: 24px;
		padding: 0 20px;
	}
}

.document-details--border {
	padding-top: 22px;
	padding-bottom: 22px;
	text-decoration: none;
	&:hover {
		text-decoration: none;
	}
}