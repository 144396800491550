.admin-info-dropdown {
	position: relative;
	display: -ms-flex;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.admin-info-dropdown:hover .admin-info-dropdown__title {
	color: var(--purple);
}
.admin-info-dropdown:hover .admin-info-dropdown__menu {
	display: block;
}
.admin-info-dropdown__menu {
	display: none;
	position: absolute;
	top: 80%;
	left: 50%;
	padding-top: 6px;
	z-index: 1;
	-moz-text-align-last: left;
	text-align-last: left;
}
.admin-info-dropdown__list {
	background: #f6f6f6;
	border: 1px solid #e3e7ee;
	padding: 4px 0;
	border-radius: 4px;
}
.admin-info-dropdown__item {
	display: block;
	padding: 6px 11px;
	font-size: 14px;
	line-height: 18px;
	white-space: nowrap;
}
.admin-info-dropdown__item:not(:last-child) {
	-webkit-box-shadow: inset 0px -1px 0px #e8e8e8;
	box-shadow: inset 0px -1px 0px #e8e8e8;
}

.admin-info-dropdown__list {
	width: 230px;
}

@media (max-width: 1280px) {
	.admin-info-dropdown__list {
		width: 210px;
	}
}

.admin-td {
	p {
		word-break: break-all;
	}
}

.admin-info-dropdown__item {
	display: block;
	white-space: inherit;
	text-align: left;
}
.table--permissions .tr {
	-ms-grid-columns: 1fr 3fr;
	grid-template-columns: 1fr 3fr;
}

.table--permissions .radio-row {
	justify-content: space-evenly;
}
.permission-name:first-letter {
	text-transform: uppercase;
}
.admin-managment-btn .plus-icon--action.plus-icon-custom {
	top: 54%;
}
.input-icon-cursor-pointer {
	cursor: pointer;
}

.content-block__inside.content-block__inside--flex {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.user-switcher {
	display: flex;
	align-items: center;
	padding: 4px 8px;
	gap: 16px;

	background: #fcfcfd;
	border: 1px solid #e6e8ec;
	border-radius: 8px;
}

.user-switcher__vip {
	font-weight: 600;
	font-size: 14px;
	line-height: 18px;
	color: #011e26;

	display: flex;
	align-items: center;
	gap: 5px;
}

.user-switcher__icon {
	height: auto;
	width: 24px;
}

.user-switcher .switch.user-switcher__switch {
	width: fit-content;
}

.content-block {
	&--user-management {
		.account-panel--top-right {
			display: flex;
			align-items: center;
			gap: 8px;
			.account-type-value {
				color: #777E90;
				font-weight: 500;
			}
		}
	}
}
