.chat {
	.input--flex {
		margin-top: 20px;
		.input-item--mid-width {
			width: 100%;
		}
	}
}

.history-message--admin.green-bg {
	color: white;
	background-color: var(--ui-button-bg);
}

.admin--time {
	display: flex;
	justify-content: flex-end;
}
.user--time {
	display: flex;
	justify-content: flex-start;
}

.chat-message__date {
	font-weight: 500;
	font-size: 12px;
	line-height: 15px;
	// padding-top: 7px;
	padding-bottom: 7px;
	color: #7a898d;
	opacity: 0.5;
}
