.two-fa-selfie {
	width: 119px;
	height: 119px;
	margin-bottom: 0;
	padding: 5px;
}
.two-fa-selfie .documents-file__preview {
	width: 100%;
	margin-bottom: 0;
}
.settings-tabs .react-tabs__tab--selected {
	font-weight: 500;
}
.image-modal {
	display: block;
	z-index: 10;
	border-radius: 10px;
	background-color: #fff;
}
.image-modal img {
	max-height: 80vh;
	border-radius: 10px;
	object-fit: cover;
	margin-bottom: -8px;
}
.image-modal.close-modal {
	display: none;
}
.documents-icon:hover + .documents-file {
	visibility: hidden;
	opacity: 1;
}
.actions-2fa-popup .button {
	font-size: 16px;
}
