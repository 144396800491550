.rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active {
  background-color: #03bc70 !important;
}

.rs-picker.time-datepicker {
  .rs-picker-toggle {
    & > svg {
      width: 1.2em;
    }
  }
}