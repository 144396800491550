.switch {
	&--disabled {
		.switch__toggler {
			cursor: not-allowed;
		}
	}
}

.text-ellipsis {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
