.status-wrapper .status--verified {
	background: #00da83;
}
.status-wrapper .status--pending {
	background: #ffa500;
}
.status-wrapper .status--inprogres {
	background: #009a5a;
}
.status-wrapper .status--processed {
	background: #71a2b0;
}
.status-wrapper .status--error {
	background: #f44336;
}
.status-wrapper .status--rejected {
	background: #f44336;
}
.status-wrapper .status--dark {
	background: #022a36;
}
.status-wrapper .status--under-dispute {
	background: #71a2b0;
}
.status--waiting {
	background: #ffe519;
}

.status-wrapper .status--on-hold {
	background: #F9A000;
}
