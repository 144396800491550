.trades {
	.table--P2P-trades .tr {
		grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr;
	}
	.table-block--trades {
		padding-bottom: 0;
	}
	.grey-text {
		color: #777e91;
	}
	.table--P2P-trades .table-body .tr {
		padding: 0;
	}
	.react-tabs__tab-panel {
	}
	&-marginTrades {
		position: relative;
	}
	.table-panel__radio-group {
		position: absolute;
		top: 5px;
		right: 0;
		z-index: 3;
	}
	@media (max-width: 1280px) {
		.table-panel__radio-group {
			position: absolute;
			top: 5px;
			right: 50px;
		}
	}
	.list-loader {
		background: #fff;
		border: 1px solid #e6e8ec;
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 400px;
	}
}

.table--spot-trades {
	.tr {
		grid-template-columns: minmax(140px, 0.9fr) minmax(70px, 0.65fr) minmax(70px, 0.65fr) minmax(
				90px,
				0.8fr
			) minmax(70px, 0.7fr) minmax(60px, 0.8fr) 0.7fr minmax(70px, 0.7fr) minmax(70px, 0.7fr) 0.8fr 0.6fr 0.6fr;
	}
}
