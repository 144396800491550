.pagination {
	li {
		cursor: pointer;
		a {
			transition: none;
		}
	}
	.disabled {
		cursor: default;
		border-color: #ccc;
		color: #ccc;
		span {
			svg {
				fill: #ccc;
			}
		}
	}
	.active {
		a {
			border: 1px solid #5bc044;
			color: #5bc044;
			cursor: default;
		}
	}
}
