.orders {
	.table-panel__radio-group {
		position: absolute;
		top: 5px;
		right: 0;
		z-index: 3;
	}
	@media (max-width: 1280px) {
		.table-panel__radio-group {
			position: absolute;
			top: 5px;
			right: 50px;
		}
	}
	// .table--spot-orders .tr {
	// 	grid-template-columns: 0.9fr 1fr minmax(100px, 1fr) minmax(50px, 0.6fr) 1fr 1fr 1fr 1fr minmax(100px, 1fr) minmax(140px, 1fr) minmax(140px, 1fr);
	// }
	// .table--P2P-orders .tr {
	// 	grid-template-columns: 0.7fr 0.8fr 0.9fr 0.9fr 0.9fr 0.9fr 0.9fr 0.9fr 1.4fr;
	// }
}
