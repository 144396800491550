.table--unidentified-deposits {
	.tr {
		grid-template-columns:
			minmax(90px, 0.5fr) minmax(80px, 0.5fr) minmax(70px, 0.4fr) minmax(140px, 1.1fr)
			1fr minmax(140px, 1.1fr) minmax(70px, 0.6fr) minmax(60px, 0.5fr) 0.9fr 0.9fr 1.1fr minmax(
				150px,
				1fr
			)
			minmax(150px, 1fr) minmax(70px, 0.4fr) minmax(110px, 0.6fr) minmax(160px, 0.6fr) minmax(
				160px,
				0.6fr
			) minmax(60px, 0.5fr);
	}
}
