.margin {
	.custom-dot {
		font-size: 14px;
	}
	.table-panel {
		min-height: 65px;
		span {
			cursor: pointer;
		}
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		gap: 0 40px;
		gap: 10px;
	}

	.table-panel-tab__item.active .table-panel-tab__link::before {
		opacity: 1;
		visibility: visible;
	}
	.table-panel-tab__item.active .table-panel-tab__link {
		color: #011e26;
		font-weight: 500;
	}
	.table-panel-tab__link {
		position: relative;
		display: inline-block;
		font-size: 16px;
		line-height: 18px;
		color: #777e91;
	}
	.table-panel-tab__link::before {
		content: '';
		position: absolute;
		bottom: -7px;
		left: 50%;
		-webkit-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);
		height: 3px;
		width: calc(100% + 10px);
		background-color: #00da83;
		opacity: 0;
	}
	.table-panel-tab__link::before {
		content: '';
		position: absolute;
		bottom: -7px;
		left: 50%;
		-webkit-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);
		height: 3px;
		width: calc(100% + 10px);
		background-color: #00da83;
		opacity: 0;
		visibility: hidden;
	}
	.table-panel-tab {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		gap: 5px 40px;
		margin-right: 20px;
	}
	.table-panel__radio-group {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-right: 10px;
	}
	.content-block__inside {
		padding: 10px 23px;
	}
	.posRel {
		position: relative;
		.last {
			position: absolute;
			right: 0;
		}
	}
}
.table--margin-balances .tr {
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
.table--margin-balances_isolated .tr {
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
.table--margin-repayment .tr {
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}
.table--margin-repayment_isolated .tr {
	grid-template-columns: 0.7fr 0.7fr 0.5fr 1.3fr 1fr 1fr 1.2fr;
}
.table--margin-borrowing_isolated .tr {
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
.table--margin-borrowing .tr {
	grid-template-columns: 1fr 1fr 1fr 1fr;
	.status--filled {
		background: #71a2b0;
		color: #fff;
	}
	.status--verified {
		background: #00da83;
		color: #fff;
	}
}
.table--margin-interest .tr {
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
.table--margin-interest_isolated .tr {
	grid-template-columns: 1fr 0.7fr 0.5fr 1fr 1fr 1fr;
}
.table--margin-liquidation_history .tr {
	grid-template-columns: 0.5fr 0.7fr 0.7fr 0.7fr 1fr;
}
.table--margin-liquidation_history_isolated .tr {
	grid-template-columns: 0.5fr 0.7fr 0.7fr 0.7fr 1fr;
}
.table--margin-marginCall .tr {
	grid-template-columns: 0.5fr 1fr 1fr;
}
.table--margin-marginCall_isolated .tr {
	grid-template-columns: 1fr 1fr 1fr 1fr;
}
.table--margin-balance .tr {
	grid-template-columns: 1fr 1fr 1fr;
}
.table--margin-balance_isolated .tr {
	grid-template-columns: 1fr 1fr 1fr;
}
.table--margin-order_history .tr {
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
	.status--filled {
		background: #71a2b0;
		color: #fff;
	}
	.status--verified {
		background: #00da83;
		color: #fff;
	}
}
.table--margin-order_history_isolated .tr {
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
	.status--filled {
		background: #71a2b0;
		color: #fff;
	}
	.status--verified {
		background: #00da83;
		color: #fff;
	}
}
