.status-item-custom {
	height: auto;
}
.stats {
	@media screen and (max-width: 1480px) {
		flex-direction: column;
	}
	&__info {
		@media screen and (max-width: 1480px) {
			width: 100%;
			max-width: inherit;
			margin-left: 0;
		}
	}
	&__list {
		@media screen and (max-width: 1480px) {
			justify-content: flex-start;
		}
		.stats-item {
			max-width: 247px;
			width: 100%;
			@media screen and (max-width: 640px) {
				max-width: inherit;
			}
		}
	}
}
.chart-custom {
	margin-right: 0px;
}

.admin-panel-custom .stats-item__img img {
	top: 0;
}

.admin-panel-custom .stats-item__arrow {
	top: auto;
	right: 10px;
}
.admin-panel-custom hr {
	border: none;
	color: rgb(240, 252, 247);
	background-color: rgb(240, 252, 247);
	height: 1px;
	margin-left: 24px;
}

.buttons-group {
	&--button {
		flex: 1;
		padding: 10px;
		margin: 5px;
		background: #ffffff;
		box-shadow: 0px 4px 8px rgb(0 0 0 / 4%), 0px 0px 2px rgb(0 0 0 / 6%),
			0px 0px 1px rgb(0 0 0 / 4%);
		border-radius: 10px;
	}
}

.buttons-group--button.active {
	color: rgb(240, 252, 247);
	background: #00da83;
}

.buttons-box {
	display: flex;
	width: 100%;
	margin-top: 15px;
	justify-content: center;
	flex-wrap: wrap;
}
