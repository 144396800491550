.permissions-icon {
	font-size: 18px;
}
.edit-icon {
	font-size: 18px;
}
.trash-icon {
	font-size: 18px;
}
.plus-icon {
	font-size: 10px;
}
.input-icon {
	font-size: 24px;
}
.standart-icon {
	font-size: 24px;
}
.sidebar-icon {
	font-size: 15px;
}
.header-logout {
	font-size: 18px;
}
