// ==========================================:
.authorization-form {
	position: relative;
}

.icon-eye2 {
	display: flex;
	align-items: center;

	&::before {
		content: '';
		width: 24px;
		height: 24px;
		display: inline-block;
		background-image: url('../../img/icons/eye_icon.svg');
		position: relative;
		top: 1px;
	}
}

.input-wrapper {
	input[type='password'] ~ .input-icon .password-type {
		display: block;
	}
	input[type='password'] ~ .input-icon .text-type {
		display: none;
	}

	input[type='text'] ~ .input-icon .password-type {
		display: none;
	}
	input[type='text'] ~ .input-icon .text-type {
		display: block;
	}
}
