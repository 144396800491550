// .table--walletman .tr {
// 	grid-template-columns: 1fr 1fr 1fr 1fr;
// }
.flex--start {
	align-self: start;
}

.popup-input--withdrawal {
	flex-direction: column;
	.popup-input-item {
		height: 35px;
		width: 100%;
		margin-bottom: 10px;
	}
	.popup-input-label {
		display: flex;
		align-items: center;
		gap: 10px;
		color: var(--rs-gray-700);
		width: 100%;
	}
}
.table-input--disable {
	display: none;
}
.rs-picker-toggle-caret.rs-icon {
	width: 15px !important;
}
