.count-label-select {
	z-index: 7;
}
.popup-window {
	z-index: 11;
}
.count-label-select .search-field {
	background-color: #04bc70;
	border-radius: 5px 0 0 5px;
	height: 42px;
	border: 1px solid var(--ui-input-border-color);
	font-size: 14px;
}
.search-field button {
	color: #ffffff;
}
.search-bar .input-item-with-select {
	border-radius: 0 5px 5px 0;
}
// .select__drop-scroll {
// 	max-height: 100%;
// }
.count-label-select .select {
	height: 42px;
	border: 1px solid var(--ui-input-border-color);
}
.input--search.mb-0 {
	margin-bottom: 0;
}
