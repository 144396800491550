.trading-pairs {
	.table-block {
		margin-top: 0;
	}
}

.margin-isolated-pairs {
	.tr {
		grid-template-columns: 2fr 2fr 2fr 3fr;
	}
}

.popup-submit .button--check {
	border: none;
	color: #fff;
}
.button-not-active svg path {
	stroke: #acacac;
}
.button-not-active {
	cursor: default;
}
