.content-block {
	.react-tabs__tab-list {
		border-bottom: none;
	}
	input[type='number'] {
		-moz-appearance: textfield;
	}

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}
}
.table-block--big-mt {
    margin-top: 32px;
}

.table--token-management-history .tr {
    -ms-grid-columns: 0.7fr 1.5fr 0.95fr 0.95fr 0.95fr 1.15fr 0.95fr 1.15fr minmax(160px, 1.2fr);
    grid-template-columns: 0.7fr 1.5fr 0.95fr 0.95fr 0.95fr 1.15fr 0.95fr 1.15fr minmax(160px, 1.2fr);
}
