.table--transactionHistory-crypto_deposit .tr {
	// grid-template-columns: 0.6fr 0.6fr 0.6fr 0.6fr 1fr 1.5fr 1fr;
	grid-template-columns: 0.6fr 0.6fr 0.6fr 1fr 1.5fr 1fr;
	tr {
		border-bottom: 1px solid #e8e8e8;
	}
}
.table--transactionHistory-crypto_withdraw .tr {
	grid-template-columns: 0.6fr 0.6fr 0.6fr 1fr 1fr 1.5fr 1fr;
	//grid-template-columns: 0.6fr 0.6fr 0.6fr 0.6fr 1fr 1fr 1.5fr 1fr;
	tr {
		border-bottom: 1px solid #e8e8e8;
	}
}
// .table--transactionHistory-fiat_deposit .tr {
// 	//grid-template-columns: 1fr 1fr 1fr 1fr;
// 	grid-template-columns: 1fr 0.8fr 1fr 1fr 1fr 1fr 1fr 1.4fr;
// 	tr {
// 		border-bottom: 1px solid #e8e8e8;
// 	}
// }
.table--transactionHistory-fiat_withdrawal .tr {
	grid-template-columns: 1fr 0.8fr 1fr 1fr 1fr 1fr 1fr 1.4fr;
	tr {
		border-bottom: 1px solid #e8e8e8;
	}
}
.table--transactionHistory_converts .tr {
	grid-template-columns: 0.6fr 0.6fr 0.6fr 0.6fr 1fr 1fr 1.5fr;
	//grid-template-columns: 0.6fr 0.6fr 0.6fr 0.6fr 0.6fr 1fr 1fr 1.5fr;
	tr {
		border-bottom: 1px solid #e8e8e8;
	}
}
.table--transactionHistory_transfers .tr {
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
	tr {
		border-bottom: 1px solid #e8e8e8;
	}
}

.table--transaction-dep-fiat .tr {
	grid-template-columns: 60px 0.7fr 60px 173px 75px 1.1fr minmax(60px, 0.9fr) 1.4fr 1fr 0.7fr 1fr 1.5fr minmax(60px, 0.6fr) minmax(60px, 0.6fr) minmax(60px, 0.6fr) 1fr minmax(110px, 0.7fr);
}

.table--history-deposit-fiat .tr {
	grid-template-columns: 60px 0.8fr 60px minmax(170px, 1fr) minmax(60px, 0.6fr) 1.4fr 1fr 0.7fr 1.1fr 0.6fr minmax(60px, 0.6fr) minmax(70px, 0.6fr) minmax(100px, 0.7fr) 177px;
}

