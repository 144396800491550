.table--spot-advertisements .tr {
	grid-template-columns: 0.5fr 0.5fr 0.5fr 0.4fr 0.5fr 0.5fr 0.5fr 0.5fr 1fr;
	tr {
		border-bottom: 1px solid #e8e8e8;
	}
}
.table--spot-dispute .tr {
	grid-template-columns: 1fr 0.5fr 0.5fr 0.5fr 0.8fr 0.5fr 1fr 1fr 1fr;
	tr {
		border-bottom: 1px solid #e8e8e8;
	}
}
.table--spot-trades_p2p .tr {
	grid-template-columns: 1.8fr 0.6fr 0.5fr 0.8fr 0.9fr 1fr 1fr 1fr 1.4fr 1.4fr;
	tr {
		border-bottom: 1px solid #e8e8e8;
	}
}
.p2p {
	.status--filled {
		background: #71a2b0;
		color: #fff;
	}
	.status--participate {
		cursor: pointer;
		background: #00da83;
		color: #fff;
	}
	.status--closed {
		cursor: pointer;
		background: #151515;
		color: #fff;
	}
	.status--under_dispute {
		background: #71a2b0;
		color: #fff;
	}
	.status--waiting {
		cursor: pointer;
		background: #00da83;
		color: #fff;
	}
	.status--waiting_confirmation {
		cursor: pointer;
		background: #00da83;
		color: #fff;
	}
	.content-block__inside {
		padding: 10px 23px;
	}
	.custom-dot {
		font-size: 14px;
	}
	.table-panel {
		min-height: 65px;
		span {
			cursor: pointer;
		}
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		gap: 0 40px;
		gap: 10px;
	}

	.table-panel-tab__item.active .table-panel-tab__link::before {
		opacity: 1;
		visibility: visible;
	}
	.table-panel-tab__item.active .table-panel-tab__link {
		color: #011e26;
		font-weight: 500;
	}
	.table-panel-tab__link {
		position: relative;
		display: inline-block;
		font-size: 16px;
		line-height: 18px;
		color: #777e91;
	}
	.table-panel-tab__link::before {
		content: '';
		position: absolute;
		bottom: -7px;
		left: 50%;
		-webkit-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);
		height: 3px;
		width: calc(100% + 10px);
		background-color: #00da83;
		opacity: 0;
	}
	.table-panel-tab__link::before {
		content: '';
		position: absolute;
		bottom: -7px;
		left: 50%;
		-webkit-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);
		height: 3px;
		width: calc(100% + 10px);
		background-color: #00da83;
		opacity: 0;
		visibility: hidden;
	}
	.table-panel-tab {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		gap: 5px 40px;
		margin-right: 20px;
	}
	.table-panel__radio-group {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-right: 50px;
	}
}
