// .table--limitations .limitations-min-max.tr {
// 	grid-template-columns: 1fr 1fr 1fr 1fr;
// 	border-bottom: 1px solid #e8e8e8;
// }
// .table-header .tr {
// 	border-bottom: 0;
// }
.table--limitations .table-header .tr {
	grid-template-columns: 1fr 1fr 1fr 1fr;
}
.limitations {
	.list-loader {
		background: #fff;
		border: 1px solid #e6e8ec;
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 400px;
	}
	.table--limitations {
		padding: 63px 15px 80px 15px;
	}
	.table-block--trades {
		border: 1px solid #e6e8ec;
		padding: 63px 15px 80px 15px;
		border-radius: 3px;
	}
	.table-limitation--withdrawal,
	.table-daily-limitation--withdrawal,
	.table-limitation--p2p {
		border: 1px solid #e6e8ec;
		border-radius: 10px;
	}
	.table-limitation--withdrawal .tr,
	.table-limitation--p2p .tr {
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
	}
	.table-daily-limitation--withdrawal .tr {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
}
// fiat
.limitations .table-limitation--withdrawalFiat .tr {
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}
.table-limitation--withdrawalFiat {
	border: 1px solid #e6e8ec;
	border-radius: 10px;
}

.table--limitations-daily-crypto .tr {
	grid-template-columns: 0.8fr 1fr 0.9fr 1fr 0.9fr 0.4fr;
}

.table--limitation-convert {
	& .tr {
		grid-template-columns: 0.8fr 1.3fr minmax(100px, 0.8fr) 0.6fr 0.6fr 1fr 1fr 1fr 1fr;
	}
}
