.table-buttons button .stroke path {
	stroke: #f44336;
}
.user-management-empty-data {
	min-height: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 20px;
	line-height: 1.3;
	color: #ccc;
}
.list-loader {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 400px;
}
.status--unverified {
	background: #71a2b0;
	color: #fff;
}
.sidebar-nav__list > li > .sidebar-nav__subitem-custom {
	padding-left: 47px;
}
.table--login-his .tr {
	grid-template-columns: 3fr 1fr 1fr 2fr;
}
.tx-link {
	overflow: hidden;
	width: 350px;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.table--deposit-his .tr {
	grid-template-columns: 0.5fr 0.6fr 1fr 1fr 350px 180px 120px;
}
.deposit-history__link {
	cursor: pointer;
}

.select-user-header {
	min-height: 42px;

	.rs-picker {
		width: 308px;

		svg {
			width: 12px;
			height: 20px;
		}
	}
}

.user-management--user .tr {
	grid-template-columns: 1fr 1fr 1fr;
}

.table--payment-cards {
	.tr {
		grid-template-columns: 1fr 1.2fr 1fr 120px;
	}
}

.user-management--account .tr {
	grid-template-columns: 1fr 3fr;
}
.user-management--account .param {
	color: #777e91;
}
.user-management--account .user-header {
	font-weight: 600;
	font-size: 18px;
}
.rad-status {
	color: #f44336;
}
.table--wallett-liquiduty .tr {
	grid-template-columns: 1fr 1fr 1fr 1fr;
}
